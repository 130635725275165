/* eslint-disable curly */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ColorType } from '@gravity-angular/models';
import { AlertsService } from '@gravity-angular/base';
import { S2sLoginMgmtService } from 'app/s2s-connection-mgmt/s2s-login-mgmt.service';
import { environment } from '@env/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { AmplifyService } from 'app/aws/amplify/amplify.service';
import { XgsUmService } from 'app/auth/xgs-service/xgs-um.service';
import { ExdlRoles, XGSCustomer } from '@common/models/user-management.model';
import { FormGroup } from '@angular/forms';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

/**
 * Notifications service for handling all notifications
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  subscription: Subscription;
  data: string;
  utilitySelection = new Subject<boolean>();
  private readonly loadingState = new Subject<boolean>();
  constructor(
    private readonly xgsUmService: XgsUmService,
    private readonly alertsService: AlertsService,
    private readonly amplifyService: AmplifyService,
    private readonly s2sLoginMgmtService: S2sLoginMgmtService,
    private readonly datadogService: DatadogService
  ) {}

  /**
   * Checks all notifications for a customer
   * @param {XGSCustomer} customer - The customer to check notifications for
   */
  checkAllNotifications(customer: XGSCustomer): void {
    const customerId = customer.customerId.toLowerCase();
    let roles = {};

    try {
      roles = this.xgsUmService.getXgsUser().roles[environment.xgsClientID];
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred checking notifications'
      });
    }

    // If user is a local admin run the s2s login count check
    if (roles && roles[ExdlRoles.LOCAL_ADMIN]) {
      this.checkS2sCount(customerId);
    }

    // this.checkDataUsage(customerId);
  }

  /**
   * Checks S2S login count for a customer
   * @param {string} customerId - The customer to check
   */
  checkS2sCount(customerId: string): void {
    this.s2sLoginMgmtService
      .getLogins(customerId)
      .then((s2sLoginResp: any[]) => {
        if (s2sLoginResp.length >= environment.maxS2SLogins) {
          this.alertsService.addAlert(
            {
              type: ColorType.neutral,
              title: 'Maximum S2S logins reached',
              message: `${customerId.toUpperCase()} currently has the maximum number of site-to-site logins in use (${
                s2sLoginResp.length
              }/${environment.maxS2SLogins})`,
              dismissable: true
            },
            0
          );
        }
      })
      .catch(e => {
        this.alertsService.addAlert({
          type: ColorType.warn,
          title: e,
          message: 'If problem persists, contact your administrator.',
          dismissable: true
        });
      });
  }

  /**
   *
   * @param value
   */
  setLoadingState(value: boolean): void {
    this.loadingState.next(value);
  }

  /**
   * Returns the loading state
   * @returns {Observable<boolean>} - The loading state
   */
  getLoadingState(): Observable<boolean> {
    return this.loadingState.asObservable();
  }

  /**
   * Fetches the S3 CSV file
   * @param {string} lambdaId - The lambda ID
   * @param {string} s3Path - The S3 path
   * @returns {Promise<any>} - The S3 CSV file
   */
  async getS3Csv(lambdaId: string, s3Path: string): Promise<any> {
    const s3Key = `${s3Path}/${lambdaId}.csv`;

    try {
      const file = await this.amplifyService.getFileFromS3(s3Key);

      return file;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching S3 CSV file'
      });
    }
  }

  /**
   * Converts the S3 CSV file to JSON
   * @param  {string} s3Key - The S3 key
   * @param {string} s3Path - The S3 path
   * @returns {Promise<object[]>} - The JSON object
   */
  async csvToJson(s3Key: string, s3Path: string): Promise<Object[]> {
    const file = await this.getS3Csv(s3Key, s3Path);
    const blob = await file.body?.text();
    const data = blob.split('/*~*\n');
    const headers = data[0].split('|');

    const tableData = [];
    for (let i = 1; i < data.length; i++) {
      const obj = {};
      const row = data[i].split('|');
      for (let j = 0; j < headers.length; j++) {
        if (headers[j]) {
          obj[headers[j]] = row[j];
        }
      }
      if (Object.keys(obj).length > 0) {
        tableData.push(obj);
      }
    }

    return tableData;
  }

  /**
   * Converts the S3 CSV file to JSON
   * @param  {string} s3Key - The S3 key
   * @param {string} s3Path - The S3 path
   * @returns {Promise<T[]>} - The typed JSON object
   */
  async csvToObject<T>(s3Key: string, s3Path: string): Promise<T[]> {
    // Get the contents of the S3 file
    const file = await this.getS3Csv(s3Key, s3Path);
    const blob = await file.body.text().then(textValue => {
      return textValue;
    });

    // Extract the headers and rows
    const data: string[] = blob.split('/*~*\n');
    const headers: string[] = data[0].split('|');
    const rows: string[] = data.slice(1);

    // For each data row, generate the appropriate object type
    const tableData: T[] = [];
    for (const row of rows) {
      const obj = {} as T;
      const rowData = row.split('|');

      for (const [index, header] of headers.entries()) {
        try {
          obj[header] = JSON.parse(rowData[index]);
        } catch {
          obj[header] = rowData[index];
        }
      }
      tableData.push(obj);
    }

    return tableData;
  }

  /**
   * Creates alert using gravity angular service, necessary for lazy loaded modules.
   * @param {ColorType} colorType ColorType provided by gravity angular model
   * @param {string} alertTitle title of alert message
   * @param {string} alertMessage message for alert
   * @param {boolean} dismissable can alert be closed
   */
  createAlert(
    colorType: ColorType,
    alertTitle: string,
    alertMessage: string,
    dismissable: boolean
  ) {
    this.alertsService.addAlert({
      type: colorType,
      title: alertTitle,
      message: alertMessage,
      dismissable
    });
  }

  /**
   * Sends value to disable or enable customer dropdown
   * @param {boolean} utility true/false
   */
  updateUtilitySelected(utility: boolean): void {
    this.utilitySelection.next(utility);
  }

  /**
   * Provides observable to monitor
   * if customer drop should be disabled
   * @returns {Observable<boolean>} - Observable to monitor if customer drop should be disabled
   */
  getUtilitySelection(): Observable<boolean> {
    return this.utilitySelection.asObservable();
  }

  /**
   * Marks form fields/controls as touched in order to highlight
   * required form fields from the beginning to reduce user
   * confusion of whats required
   * @param {FormGroup} form form group that needs to be marked as touched
   */
  markAllFieldsAsTouched(form: FormGroup): void {
    Object.values(form.controls).forEach(control => {
      control.markAllAsTouched();
    });
  }
}
