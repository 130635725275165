import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class PreviousRouteService {
  private currentUrl: string;
  private _history = [];

  constructor(private readonly router: Router) {
    this.currentUrl = this.router.url;
  }

  initialize(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentPath = event.url.split('?')[0];
        if (!this.currentUrl.includes(currentPath)) {
          this.addToHistory(this.currentUrl);
        }
        this.currentUrl = event.url;
      }
    });
  }

  addToHistory(url: string): void {
    if (this._history.length > 9) {
      this._history.shift();
    }

    this._history = [...this._history, url];
  }

  getPreviousUrl(): string {
    return this._history.length ? this._history[this._history.length - 1] : '';
  }

  getHistory(): string[] {
    return this._history;
  }
}
