import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { SelectOption } from './select-option.model';
import { CommodityOption } from './commodity-option.model';
import { Commodity } from './commodity.types';
import { UtilityOptionValue } from '@common/models/user-management.model';

/**
 * Component for commodity switcher/customer dropdown from header
 */
@Component({
  selector: 'app-commodity-switcher',
  templateUrl: './commodity-switcher.component.html',
  styleUrls: ['./commodity-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommoditySwitcherComponent {
  @Input()
  commodityOptions: CommodityOption[];

  @Input()
  utilityOptions: SelectOption[];

  @Input()
  selectedCommodity: string;

  @Input()
  selectedUtility: UtilityOptionValue;

  @Output()
  readonly commodityChanged: EventEmitter<Commodity>;

  @Output()
  readonly utilityChanged: EventEmitter<UtilityOptionValue>;

  constructor() {
    this.commodityChanged = new EventEmitter<Commodity>();
    this.utilityChanged = new EventEmitter<UtilityOptionValue>();
  }

  /**
   * Update the selected commodity
   */
  onCommodityChange(): void {
    this.commodityChanged.next(this.selectedCommodity as Commodity);
  }

  /**
   * Update the selected utility
   */
  onUtilityChange(): void {
    this.utilityChanged.next(this.selectedUtility);
  }
}
