import { Injectable } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class OidcStorageService implements AbstractSecurityStorage {
  oidcKey: string;

  constructor() {}

  /**
   * Reads data from local storage
   * @param key configID for OIDC flow
   * @returns config data/tokens for OIDC flow if found or null
   */
  read(key: string): string {
    this.oidcKey = key;

    return localStorage.getItem(key);
  }

  /**
   * Stores to local storage
   * @param key configID for OIDC flow
   * @param value value for configID (userdata, tokens, etc.)
   */
  write(key: string, value: string): void {
    this.oidcKey = key;

    localStorage.setItem(key, value);
  }

  /**
   * Removes local storage values
   * @param key configID of OIDC flow
   */
  remove(key: string): void {
    this.oidcKey = key;

    localStorage.removeItem(key);
  }

  /**
   * Removes all localStorage values
   * @param key
   */
  clear(key?: string): void {
    if (this.oidcKey) {
      localStorage.removeItem(this.oidcKey);
    } else if (key) {
      localStorage.removeItem(key);
    }
  }
}
