/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import {
  Dashboard,
  DashboardParams,
  NativeFilterKey,
  NativeFilters,
  QueryContextFilter
} from '@common/models/superset.model';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { AmplifyService } from 'app/aws/amplify/amplify.service';
import { ApiResponse } from '../../client-common/models/api.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { NotificationsService } from '@common/notifications/notifications.service';
import { ColorType } from '@gravity-angular/models';
import { UmService } from 'app/auth/um-service/um.service';

/**
 * Service to interact with Superset APIs
 */
@Injectable({
  providedIn: 'root'
})
export class SupersetService {
  constructor(
    private readonly amplifyService: AmplifyService,
    private readonly notificationService: NotificationsService,
    private readonly umService: UmService,
    private readonly cookieService: CookieService
  ) {}

  /**
   * Get Superset token from cookie
   * @returns Superset token
   */
  get supersetToken(): string {
    return this.cookieService.get(
      `${environment.environmentName}_superset_token`
    );
  }

  /**
   * Authorizes user in superset for api calls using XGS token
   * @returns Promise containing api response
   */
  async supersetAuth(): Promise<void> {
    let customer = this.cookieService.get(
      `${environment.environmentName}_customer`
    );
    if (customer === '') {
      customer = null;
    }

    await this.umService.setAuthResults();
    const params = {
      body: {
        provider: this.amplifyService.amplifyIdp,
        accessToken: this.umService.authResults?.access_token,
        customer
      }
    };

    try {
      const response = await this.amplifyService.callAPI<ApiResponse>(
        'post',
        'superset-api',
        '/auth',
        params
      );
      const expirationDate =
        jwtDecode<JwtPayload>(response.data.toString()).exp * 1000;
      this.cookieService.set(
        `${environment.environmentName}_superset_token`,
        response.data.toString(),
        new Date(expirationDate),
        '/',
        this.domainHelper(),
        true,
        'None'
      );
    } catch (error) {
      this.notificationService.createAlert(
        ColorType.danger,
        $localize`:@@superset-auth-error_msg_title:Superset Auth Failed`,
        $localize`:@@superset-auth-error_msg_desc:If problem persists contact your admin`,
        true
      );
    }
  }

  /**
   * Helper function to extract
   * domain and if developing locally
   * @returns domain name
   */
  domainHelper(): string {
    const url = new URL(window.location.origin);
    const loc = url.href;
    const domain =
      !environment.production && loc.startsWith('http://localhost:4200/')
        ? 'localhost'
        : environment.domain;

    return domain;
  }

  /**
   * Checks if user has valid Superset
   * token and if not begins auth process
   */
  async checkSupersetAuth(): Promise<void> {
    const token = this.supersetToken;
    if (token) {
      if (new Date().getTime() >= jwtDecode<JwtPayload>(token).exp * 1000) {
        await this.supersetAuth();
      }
    } else {
      await this.supersetAuth();
    }
  }

  /**
   * Called when customer is changed from
   * dropdown box to remove superset-auth token
   */
  invalidateToken(): void {
    this.cookieService.delete(
      `${environment.environmentName}_superset_token`,
      '/',
      this.domainHelper()
    );
  }

  /**
   * Returns Superset Dashboards user has access to
   * @param customerId
   * @returns List of dashboard objects
   */
  async getDashboards(customerId: string): Promise<Dashboard[]> {
    await this.checkSupersetAuth();

    const columns = [
      'id',
      'dashboard_title',
      'published',
      'status',
      'changed_by_name',
      'changed_on_utc',
      'owners.id',
      'owners.username',
      'certification_details',
      'url'
    ];
    const params = {
      body: {
        supersetToken: this.supersetToken,
        customerId,
        columns
      }
    };

    const response = await this.amplifyService.callAPI<
      ApiResponse<Dashboard[]>
    >('post', 'superset-api', '/get-dashboards', params);

    return response.data;
  }

  /**
   * Gets a list of filters associated with a Superset dashboard
   * @param dashboardId ID of the Superset Dashboard
   * @returns List of filters applicable to the Dashboard
   */
  async getDashboardFilters(dashboardId: string): Promise<DashboardParams> {
    await this.checkSupersetAuth();
    const params = {
      body: {
        supersetToken: this.supersetToken,
        dashboardId
      }
    };

    const response = await this.amplifyService.callAPI<
      ApiResponse<DashboardParams>
    >('post', 'superset-api', '/get-dashboard-params', params, true);

    return response.data;
  }

  /**
   * Gets a list of column values for a dashboard filter dropdown
   * @param datasetId ID of the dataset to query
   * @param columnName Column name to get values for
   * @param filters Query context filters to filter the column values by
   * @returns A list of string values for the column
   */
  async getDashboardFilterValues(
    datasetId: number,
    columnName: string,
    filters: QueryContextFilter[]
  ): Promise<string[]> {
    await this.checkSupersetAuth();
    const params = {
      body: {
        supersetToken: this.supersetToken,
        datasetId,
        columnName,
        filters
      }
    };

    const response = await this.amplifyService.callAPI<ApiResponse<string[]>>(
      'post',
      'superset-api',
      '/get-dashboard-param-values',
      params
    );

    return response.data;
  }

  /**
   * Performs API call to store native_filter for dashboard
   * @param dashboardId superset id for dashboard
   * @param nativeFilter native_filter to be stored
   * @returns native_filter_key to use in url params
   */
  async createDashboardFilterState(
    dashboardId: string,
    nativeFilter: NativeFilters
  ): Promise<NativeFilterKey> {
    await this.checkSupersetAuth();
    const params = {
      body: {
        dashboardId,
        supersetToken: this.supersetToken,
        nativeFilter: JSON.stringify(nativeFilter)
      }
    };

    const response = await this.amplifyService.callAPI<
      ApiResponse<NativeFilterKey>
    >('post', 'superset-api', '/create-dashboard-filter-state', params);

    return response.data;
  }

  /**
   * Performs API call to sync professional services reports
   * @returns API response
   */
  async syncPsReports(): Promise<ApiResponse> {
    await this.checkSupersetAuth();
    const params = {
      headers: {
        xgs: this.umService.authResults?.access_token
      }
    };

    const response = await this.amplifyService.callAPI<ApiResponse>(
      'post',
      'superset-api',
      '/superset-prof-services-sync',
      params
    );

    return response;
  }
}
