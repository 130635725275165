<div class="commodity-switcher">
  <div
    class="switcher-container"
    *ngIf="
      utilityOptions.length > 1 || commodityOptions.length > 1;
      else singlesLayout
    "
  >
    <p-dropdown
      class="utility-dropdown"
      [options]="utilityOptions"
      [filter]="true"
      filterBy="name"
      filterMatchMode="contains"
      [showClear]="false"
      [(ngModel)]="selectedUtility"
      optionValue="value"
      optionLabel="name"
      [resetFilterOnHide]="true"
      (onHide)="onUtilityChange()"
      itemDisabled="disabled"
      *ngIf="utilityOptions.length > 1; else singleUtility"
    >
      <ng-template let-selectedItem pTemplate="selectedItem">
        <div class="dropdown-item">
          <div class="dropdown-text">
            {{ selectedItem?.name }}
          </div>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div class="dropdown-item">
          <div class="dropdown-text">{{ item.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>

    <ng-template #singleUtility>
      <span class="single-item-left">{{ utilityOptions[0].name }}</span>
    </ng-template>

    <p-dropdown
      class="commodity-dropdown"
      [options]="commodityOptions"
      [showClear]="false"
      [(ngModel)]="selectedCommodity"
      (onHide)="onCommodityChange()"
      optionValue="value"
      optionLabel="name"
      [resetFilterOnHide]="true"
      *ngIf="commodityOptions.length > 1; else singleCommodity"
    >
      <ng-template let-selectedItem pTemplate="selectedItem">
        <div class="dropdown-item">
          <mat-icon>{{ selectedItem.icon }}</mat-icon>
          <div class="dropdown-text">
            {{ selectedItem.name }}
          </div>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div class="dropdown-item">
          <mat-icon>{{ item.icon }}</mat-icon>
          <div class="dropdown-text">{{ item.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
    <ng-template #singleCommodity>
      <span class="single-item-right">
        <mat-icon>{{ commodityOptions[0].icon }}</mat-icon>
        {{ commodityOptions[0].name }}
      </span>
    </ng-template>
  </div>

  <ng-template #singlesLayout>
    <div class="single-layout">
      <span class="single-layout-item">{{ utilityOptions[0].name }}</span>
      <span class="single-layout-item">
        <mat-icon>{{ commodityOptions[0].icon }}</mat-icon>
        {{ commodityOptions[0].name }}
      </span>
    </div>
  </ng-template>
</div>
