import { Component, OnInit } from '@angular/core';
import { CustomTranslatorService } from 'app/shared/services/custom-translator/custom-translator.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  constructor(public customTranslatorService: CustomTranslatorService) {}

  ngOnInit(): void {}

  learnMore(url: string): void {
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location.href = url;
  }
}
