/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from '@env/environment';
import {
  RolePatch,
  UDLUserData,
  XGSRole,
  XGSUser,
  XGSUsers,
  XGSCustomersAPI,
  XGSCustomer,
  RedshiftRole,
  XGSUserClientRole,
  XgsOxiRoles,
  OidcConfigIds
} from '@common/models/user-management.model';
import { LoadingService } from '@gravity-angular/layout';
import { AlertsService } from '@gravity-angular/base';
import { ColorType } from '@gravity-angular/models';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserFunctionsService } from 'app/user-management/user/user-functions-service/user-functions.service';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';
import { CustomTranslatorService } from 'app/shared/services/custom-translator/custom-translator.service';

/**
 * Service to handle all XGS User Management related API calls
 */
@Injectable({
  providedIn: 'root'
})
export class XgsUmService {
  xgsBaseURL = environment.xgsBaseURL;
  xgsActiveUser: XGSUser = {} as XGSUser;
  ddbUsers: Object;
  xgsUsers = new BehaviorSubject([] as XGSUser[]);
  xgsUserTotal = 0;
  xgsCustomers = [] as XGSCustomer[];
  xgsRoles = [] as XGSRole[];
  activeXgsUserRoles = [] as XGSRole[];
  userList = [] as XGSUser[];
  xgsAuthResults = {} as XGSAuthResults;
  genericErrorMessage = $localize`:@@xgs-um-service_generic:If problem persists, please contact your admin.`;

  eXdlCreatedRoles = [
    'LocalAdmin',
    'Distributor',
    'PowerUser',
    'ReadOnly',
    'SuperAdmin',
    'ProfessionalServices',
    'TechServices',
    'XylemClientSuccess',
    'BiAccess',
    'AppstoreAccess',
    'AnomalyDetection'
  ];

  constructor(
    private readonly http: HttpClient,
    private readonly alertsService: AlertsService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly datadogService: DatadogService,
    private readonly customTranslatorService: CustomTranslatorService,
    private readonly userFunctionsService: UserFunctionsService,
    private readonly loadingService: LoadingService
  ) {
    this.setXgsAuthResults();
  }

  /**
   * Sets XGS Auth Results from OIDC Security Service
   */
  async setXgsAuthResults(): Promise<void> {
    this.xgsAuthResults = await lastValueFrom(
      this.oidcSecurityService.getAuthenticationResult(OidcConfigIds.XGS)
    );
  }

  /**
   * Get XGS active user
   * @returns XGS active user
   */
  getXgsUser(): XGSUser {
    return this.xgsActiveUser;
  }

  /**
   * Get active user's locale setting
   * @returns locale setting for active user
   */
  getLocale(): string {
    const user = this.getXgsUser();

    return user.user_metadata?.locale ?? 'en_US';
  }

  /**
   * Set XGS active user
   * @param userData UDL user object
   */
  async setUser(userData: UDLUserData): Promise<void> {
    this.xgsActiveUser = await this.getUserDetailHelper(userData.id);
    userData.user_metadata =
      this.xgsActiveUser?.user_metadata ?? userData.user_metadata;
    userData.xgsRoles =
      this.xgsActiveUser?.roles[environment.xgsClientID] ?? {};
    userData.xgsPermissions = await this.getUserRolesHelper(userData.id, true);
  }

  /**
   * Makes get users call from XGS
   * @param params XGS User Pagination parameters
   * @returns XGS Get Users Observable
   */
  getUsers(params: XGSUserParameters): Observable<XGSUsers> {
    const getUserUrl = `${this.xgsBaseURL}/v1/users?size=${params.size}&page=${params.page}`;

    return this.http.get<XGSUsers>(getUserUrl, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * Makes paginated callse to XGS get users
   * @param page page number
   * @returns paginated results from XGS users
   */
  async paginateUsers(page: number): Promise<XGSUsers> {
    const params = this.buildGetUsersParams(page);

    try {
      const response = await lastValueFrom(this.getUsers(params));

      this.xgsUserTotal =
        response._embedded?.usersDToes?.length + this.xgsUserTotal;

      return response;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching paginated users'
      });

      this.alertsService.addAlert({
        type: ColorType.warn,
        title: this.customTranslatorService.translate(
          $localize`:@@xgs-um-service_userlistError:Error Retrieving Users`,
          'xgs-um-service_userlistError'
        ),
        message: this.customTranslatorService.translate(
          '',
          'xgs-um-service_generic'
        ),
        dismissable: true
      });
    }
  }

  /**
   * Builds parameters for get users API call
   * @param page page number
   * @returns XGS User Parameters
   */
  buildGetUsersParams(page: number): XGSUserParameters {
    const adminUser = Object.prototype.hasOwnProperty.call(
      this.xgsActiveUser.roles[environment.xgsClientID],
      XgsOxiRoles.APP_ADMIN
    );
    const size =
      this.xgsActiveUser && adminUser
        ? 500
        : page > 1
        ? environment.xgsUsersPageSize + 100
        : environment.xgsUsersPageSize;

    const params: XGSUserParameters = {
      size,
      page
    };

    return params;
  }

  /**
   * API call to xgs to get user by email address
   * @param email email address used to search for user by
   * @returns observable that is subscribed to to get API response
   */
  getUserByEmail(email: string): Observable<any> {
    return this.http.get(`${this.xgsBaseURL}/v1/users/byidoremail/${email}`, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * Helper function in place to catch failed calls
   * @param email user's email
   * @returns user if present in XGS
   */
  async getUserByEmailHelper(email: string): Promise<any> {
    try {
      const user = await lastValueFrom(this.getUserByEmail(email));
      if (user?.roles?.[environment.xgsClientID]) {
        return user;
      }

      return;
    } catch (error) {
      if (error.status !== 404) {
        this.datadogService.errorTracking(error, {
          message: 'Error occurred while fetching user by email...'
        });
      }

      return;
    }
  }

  /**
   * API call to xgs to get user by user id
   * @param userId user id used to search for user by
   * @param token token used to authenticate user
   * @returns observable that is subscribed to to get API response
   */
  getUserDetail(userId: string, token?: string): Observable<any> {
    return this.http.get(`${this.xgsBaseURL}/v1/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token ?? token}`,
        observe: 'response'
      }
    });
  }

  /**
   * Helper function to get a user's XGS info
   * @param userId id of user being retrieved
   * @returns User's XGS information
   */
  async getUserDetailHelper(userId: string): Promise<XGSUser> {
    try {
      if (userId) {
        const token =
          this.xgsAuthResults?.access_token ||
          (await lastValueFrom(
            this.oidcSecurityService.getAccessToken(OidcConfigIds.XGS)
          ));
        const response = await lastValueFrom(this.getUserDetail(userId, token));

        return response;
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching user details...'
      });
      this.alertsService.addAlert({
        type: ColorType.warn,
        title: this.customTranslatorService.translate(
          $localize`:@@xgs-um-service_userDetailError:Failed to get user's info`,
          'xgs-um-service_userDetailError'
        ),
        message: this.customTranslatorService.translate(
          '',
          'xgs-um-service_generic'
        ),
        dismissable: true
      });

      return null;
    }
  }

  /**
   * API call to xgs to edit user
   * @param user Object containing user data being used to edit user
   * @returns observable that is subscribed to to get API response
   */
  editUser(user: XGSUser | UDLUserData): Observable<any> {
    return this.http.patch(`${this.xgsBaseURL}/v1/users/${user.id}`, user, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * API call to xgs to edit users roles
   * @param roleItems contains roles to be ASSIGN or REVOKE
   * @param user Object containing user data that's roles are being changed
   * @returns observable that is subscribed to to get API response
   */
  patchUserRoles(roleItems: RolePatch, user: XGSUser): Observable<any> {
    return this.http.patch(
      `${this.xgsBaseURL}/v1/users/${user.id}/roles`,
      roleItems,
      {
        headers: {
          Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
          observe: 'response'
        }
      }
    );
  }

  /**
   * Helper function to edit user's XGS roles
   * @param roleItems contains roles to be ASSIGN or REVOKE
   * @param user user whose roles are being changed
   * @returns results of role change
   */
  async patchUserRolesHelper(
    roleItems: RolePatch,
    user: XGSUser
  ): Promise<any> {
    try {
      const response = await lastValueFrom(
        this.patchUserRoles(roleItems, user)
      );

      return response;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while updating user roles...'
      });
      this.alertsService.addAlert({
        type: ColorType.warn,
        title: this.customTranslatorService.translate(
          $localize`:@@xgs-um-service_editUserRolesError:Failed to update user roles`,
          'xgs-um-service_editUserRolesError'
        ),
        message: this.customTranslatorService.translate(
          '',
          'xgs-um-service_generic'
        ),
        dismissable: true
      });
    }
  }

  /**
   * API call to xgs to delete user's claims
   * @param userId user id used to identify user's claims to be deleted
   * @returns observable that is subscribed to to get API response
   */
  deleteClaims(userId: string): Observable<any> {
    return this.http.delete(`${this.xgsBaseURL}/v1/users/${userId}/claims`, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * Helper function to delete user's claims
   * @param userId user id used to identify user's claims to be deleted
   * @returns results of deletion
   */
  async deleteClaimsHelper(userId: string): Promise<boolean> {
    try {
      await lastValueFrom(this.deleteClaims(userId));

      return true;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while removing user claims...'
      });
      this.alertsService.addAlert({
        type: ColorType.warn,
        title: this.customTranslatorService.translate(
          $localize`:@@xgs-um-service_deleteClaimsError:Failed to update user claims`,
          'xgs-um-service_deleteClaimsError'
        ),
        message: this.customTranslatorService.translate(
          '',
          'xgs-um-service_generic'
        ),
        dismissable: true
      });
    }
  }

  /**
   * API call to xgs to delete user
   * @param user user data used to identify user's claims to be deleted
   * @returns observable that is subscribed to to get API response
   */
  deleteUser(user: XGSUser): Observable<any> {
    return this.http.delete(`${this.xgsBaseURL}/v1/users/${user.id}`, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * Helper function to delete user from XGS
   * @param user user to be deleted
   * @returns results of deletion
   */
  async deleteUserHelper(user: XGSUser): Promise<any> {
    try {
      const xgsResponse = await lastValueFrom(this.deleteUser(user));

      return xgsResponse;
    } catch (error) {
      this.alertsService.addAlert({
        type: ColorType.warn,
        title: this.customTranslatorService.translate(
          $localize`:@@xgs-um-service_deleteUserError:Failed to delete user`,
          'xgs-um-service_deleteUserError'
        ),
        message: this.customTranslatorService.translate(
          '',
          'xgs-um-service_generic'
        ),
        dismissable: true
      });

      return null;
    }
  }

  /**
   * API call to xgs to get a user's roles
   * @param userId user id used to identify user's roles to be retrieved
   * @returns observable that is subscribed to to get API response
   */
  getUserRoles(userId: string): Observable<any> {
    return this.http.get(`${this.xgsBaseURL}/v1/users/${userId}/roles`, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * Helper function to get a User's XGS roles
   * @param userId id of user's roles to be gotten
   * @param active is this for the current active user
   * @returns User's XGS roles
   */
  async getUserRolesHelper(userId: string, active = false): Promise<XGSRole[]> {
    try {
      if (userId) {
        const response = await lastValueFrom(this.getUserRoles(userId));

        if (active) {
          this.activeXgsUserRoles = response;
        }

        return response;
      }

      return [];
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching user roles...'
      });
      this.alertsService.addAlert({
        type: ColorType.warn,
        title: this.customTranslatorService.translate(
          $localize`:@@xgs-um-service_getUserRolesError:Failed to get user's roles`,
          'xgs-um-service_getUserRolesError'
        ),
        message: this.customTranslatorService.translate(
          '',
          'xgs-um-service_generic'
        ),
        dismissable: true
      });

      return [];
    }
  }

  /**
   * API call to xgs to get a customers user has access to
   * @returns observable that is subscribed to to get API response
   */
  getCustomers(): Observable<any> {
    return this.http.get(`${this.xgsBaseURL}/v1/customers`, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * Sorts customer list based on customer name
   * @param customer1 first customer to be compared
   * @param customer2 second customer to be compared
   * @returns sorted customer list
   */
  sortCustomerList(customer1: XGSCustomer, customer2: XGSCustomer): number {
    return customer1.customerId.localeCompare(customer2.customerId);
  }

  /**
   * Sorts customers and removes customer used to represent internal users
   * @param xgsCustomers list of XGS customers
   * @returns formatted customer list
   */
  formatCustomerList(xgsCustomers: XGSCustomer[]): XGSCustomer[] {
    try {
      let customerList = xgsCustomers.sort(this.sortCustomerList);
      const allCust = customerList.find(customer => {
        return customer.customerId === 'AllCustomers';
      });
      customerList = customerList.filter(customer => {
        return customer.customerId !== 'AllCustomers';
      });
      if (allCust) {
        customerList.unshift(allCust);
      }

      return customerList;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while formatting customers...'
      });

      return [];
    }
  }

  /**
   * Gets XGS Customers
   * @returns list of XGS Customers
   */
  async getXGSCustomers(): Promise<XGSCustomer[]> {
    let customerList = [];
    try {
      if (this.xgsCustomers.length > 0) {
        return this.xgsCustomers;
      }
      const response: XGSCustomersAPI = await lastValueFrom(
        this.getCustomers()
      );
      customerList = this.formatCustomerList(response.customers);
      this.xgsCustomers = customerList;

      return customerList;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching customers...'
      });
      this.xgsCustomers = customerList;

      return customerList;
    }
  }

  /**
   * API call to xgs to get roles available in UDL
   * @returns observable that is subscribed to to get API response
   */
  getRoles(): Observable<any> {
    return this.http.get(`${this.xgsBaseURL}/v1/roles`, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * Helper function used to get XGS UDL roles
   * @returns list of XGS roles
   */
  async getRolesHelper(): Promise<XGSRole[]> {
    try {
      const response = await lastValueFrom(this.getRoles());
      this.xgsRoles = response.sort(this.sortRoles);

      return response;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching roles...'
      });
      this.alertsService.addAlert({
        type: ColorType.warn,
        title: this.customTranslatorService.translate(
          $localize`:@@xgs-um-service_getRolesError:Failed to get XDL Roles`,
          'xgs-um-service_getRolesError'
        ),
        message: this.customTranslatorService.translate(
          '',
          'xgs-um-service_generic'
        ),
        dismissable: true
      });

      return [];
    }
  }

  /**
   * Sorts roles based on role name
   * @param role1 first role to be compared
   * @param role2 second role to be compared
   */
  sortRoles(role1: XGSRole, role2: XGSRole): number {
    return role1.name.localeCompare(role2.name);
  }

  /**
   * API call to xgs to get data for specific role
   * @param roleId role id used to identify role data to be retrieved
   * @returns observable that is subscribed to to get API response
   */
  getRoleDetail(roleId: string): Observable<any> {
    return this.http.get(`${this.xgsBaseURL}/v1/roles/${roleId}`, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * Helper function to get a XGS role and all it's info
   * @param roleId id of role to get
   * @returns full info on XGS role
   */
  async getRoleHelper(roleId: string): Promise<XGSRole> {
    try {
      const role = await lastValueFrom(this.getRoleDetail(roleId));

      return role;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while fetching role details...'
      });
      this.alertsService.addAlert({
        type: ColorType.warn,
        title: this.customTranslatorService.translate(
          $localize`:@@xgs-um-service_getRoleError:Failed to get XDL Role`,
          'xgs-um-service_getRoleError'
        ),
        message: this.customTranslatorService.translate(
          '',
          'xgs-um-service_generic'
        ),
        dismissable: true
      });

      return null;
    }
  }

  /**
   * API call to xgs to create a role
   * @param role role to be created
   * @returns observable that is subscribed to to get API response
   */
  createRole(role: XGSRole): Observable<any> {
    return this.http.post(`${this.xgsBaseURL}/v1/roles`, role, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * API call to xgs to create a user
   * @param user user to be created
   * @returns observable that is subscribed to to get API response
   */
  createUser(user: XGSUser): Observable<any> {
    return this.http.post(`${this.xgsBaseURL}/v1/users`, user, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * API call to xgs to edit a role
   * @param role new role info
   * @param roleId id of role to be edited
   * @returns observable that is subscribed to to get API response
   */
  editRole(role: XGSRole, roleId: string): Observable<any> {
    return this.http.patch(`${this.xgsBaseURL}/v1/roles/${roleId}`, role, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * API call to xgs to attach user claim to user
   * @param response response containing AWS info for users access via BI Tool
   * @param redshiftRole AWS IAM role arn to be attached to user
   * @param userId id for user to have claims attached
   * @returns observable that is subscribed to to get API response
   */
  attachIAMIdentity(
    redshiftRole: RedshiftRole,
    userId: string
  ): Observable<any> {
    const claim = {
      roleArn: redshiftRole.roleArn,
      DbGroups: redshiftRole.customers
    };

    return this.http.put(
      `${this.xgsBaseURL}/v1/users/${userId}/claims`,
      claim,
      {
        headers: {
          Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
          observe: 'response'
        }
      }
    );
  }

  /**
   * Helper function to attach IAM identity to user via user claims
   * @param redshiftRole AWS IAM role arn to be attached to user
   * @param userId id of user to have role attached
   */
  async attachIAMIdentityHelper(
    redshiftRole: RedshiftRole,
    userId: string
  ): Promise<any> {
    try {
      const response = await lastValueFrom(
        this.attachIAMIdentity(redshiftRole, userId)
      );

      return response;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred while updating user claims...'
      });
      this.alertsService.addAlert({
        type: ColorType.warn,
        title: this.customTranslatorService.translate(
          $localize`:@@xgs-um-service_addClaimsError:Failed to Update User Claims`,
          'xgs-um-service_addClaimsError'
        ),
        message: this.customTranslatorService.translate(
          '',
          'xgs-um-service_generic'
        ),
        dismissable: true
      });
    }
  }

  /**
   * API call to xgs to delete a role
   * @param role role to be deleted
   * @returns observable that is subscribed to to get API response
   */
  deleteRole(role: XGSRole): Observable<any> {
    return this.http.delete(`${this.xgsBaseURL}/v1/roles/${role.id}`, {
      headers: {
        Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
        observe: 'response'
      }
    });
  }

  /**
   * API call to xgs to resend email verification
   * @param user user to have email sent to
   * @returns observable that is subscribed to to get API response
   */
  resendEmailVerification(user: XGSUser): Observable<any> {
    return this.http.post(
      `${this.xgsBaseURL}/v1/users/${user.id}/verifyemail`,
      null,
      {
        headers: {
          Authorization: `Bearer ${this.xgsAuthResults?.access_token}`,
          observe: 'response'
        },
        responseType: 'text'
      }
    );
  }

  /**
   * Loops through the paginated results of
   * calls to XGS Users to store users in memory
   */
  async generateUsers(): Promise<void> {
    this.loadingService.showLoading(true);
    this.ddbUsers = await this.userFunctionsService.getUserTracking(
      this.xgsAuthResults?.access_token
    );
    let pageUsers = [] as XGSUser[];
    let pageTotal = 3;
    let pageNum = 0;

    do {
      this.loadingService.showLoading(true);
      const response = await this.paginateUsers(pageNum);

      if (!response) {
        pageUsers = pageUsers.length > 0 ? pageUsers : [];
        break;
      }

      pageTotal = response.page.totalPages;
      if (response._embedded) {
        pageUsers = pageUsers
          .concat(this.addLoginhistory(response._embedded.usersDToes))
          .sort(this.sortUsers);
        this.xgsUsers.next(pageUsers);
        this.userList = pageUsers;
      }
      pageNum += 1;
    } while (pageNum <= pageTotal);

    this.loadingService.showLoading(false);
  }

  /**
   * Sorts users based on email address
   * @param user1 - User object to compare
   * @param user2 - User object to compare
   */
  sortUsers(user1: XGSUser, user2: XGSUser): number {
    return user1.email.localeCompare(user2.email);
  }

  /**
   * Adds the last login time to each user object in the provided array.
   * @param pageUsers - Array of user objects to update with last login time.
   * @returns The updated array of user objects with last login time added.
   */
  addLoginhistory(pageUsers: XGSUser[]): XGSUser[] {
    for (const user of pageUsers) {
      if (this.ddbUsers[user.id]?.last_login && !user.last_login) {
        user.last_login = this.ddbUsers[user.id].last_login;
      }
    }

    return pageUsers;
  }

  /**
   * Helper function to get user's XGS roles
   * @param roles - The roles object containing the customer ID and role name.
   * @returns The user's XGS roles (OXI roles only).
   */
  getOXIRoles(roles: XGSUserClientRole): string[] {
    return Object.keys(roles[environment.xgsClientID]).filter(role => {
      return role.includes('OXI:');
    });
  }

  /**
   * Helper function to return role name in the event of customer scoped
   * @param role - The role object containing customerId and name.
   * @returns The role name, prefixed with customerId if available.
   */
  getRoleName(role: XGSRole): string {
    return role.customerId ? `${role.customerId}::${role.name}` : role.name;
  }
}

export interface XGSUserParameters {
  size: number;
  page: number;
  customers?: string;
}

export interface XGSAuthResults {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: number;
  refresh_expires_in: number;
  scope: string;
  session_state: string;
  state: string;
  token_type: string;
}
