<xg-modal #confirmationModal
    [size]="SizeType.sm"
    [primaryActionText]="buttonText()"
    (primaryClick)="close('confirm')"
    (secondaryClick)="close(null)">
    <div title>
        <span>
            {{title}}
        </span>
    </div>
    <div content>
        <strong>{{emphasis}}</strong>
        <p>{{description}}</p>
    </div>
</xg-modal>