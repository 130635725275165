import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit, OnChanges {
  @Input() id = '';
  @Input() title = '';
  @Input() url = '';

  sanitizedUrl: SafeResourceUrl;

  constructor(private readonly sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.sanitizeUrl();
  }

  // If the id changes, reload the iframe
  ngOnChanges(changes: SimpleChanges) {
    if (changes.id && changes.id.currentValue !== changes.id.previousValue) {
      this.sanitizeUrl();
      if (document.getElementById(this.id)) {
        document.getElementById(this.id)['contentWindow'].location.reload(true);
      }
    }
  }

  sanitizeUrl(): void {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
