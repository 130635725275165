import { Injectable } from '@angular/core';
import { Commodity } from '@gravity-angular/models';

@Injectable({ providedIn: 'root' })
export class CommoditySwitcherService {

  constructor() {}

  updateCommodity(
    commodity: Commodity,
    cookieDomain: string,
    redirectLocation = '/'
  ): void {}

  updateUtility(
    utilityName: string,
    cookieDomain: string,
    utilityDataSessionKey: string,
    redirectLocation = '/'
  ): void {}

  getCommodityForUtility(
    commodity: Commodity,
    utilityName: string,
    utilityDataSessionKey: string
  ): void {}
}
