<div class="landing" *ngIf="customTranslatorService.userLocaleData">
  <app-carousel-slider></app-carousel-slider>
  <div class="intro-connect">
    <div class="introduction">
      <h1 style="font-size: calc(0.75em + 2.3vmin);" i18n="@@landing_landing_welcome" [textContent]="'landing_landing_welcome' | translate">Welcome to the Xylem Data Lake!</h1>
      <p class="landingFont" i18n="@@landing_landing_description" [textContent]="'landing_landing_description' | translate">
        The Xylem Data Lake provides flexible read only access to FlexNet data decoupled from headend upgrade processes.      
      </p>
      <p class="landingFont" i18n="@@landing_landing_functionality_list" [textContent]="'landing_landing_functionality_list' | translate">
      The core functionality of the Xylem Data Lake includes:
      </p>
      <ul class="landingFont">
        <li i18n="@@landing_landing_functionality_item_1" [textContent]="'landing_landing_functionality_item_1' | translate">A space to run queries and schedule reports</li>
        <li i18n="@@landing_landing_functionality_item_2" [textContent]="'landing_landing_functionality_item_2' | translate">A space to build Business Intelligence dashboards</li>
        <li i18n="@@landing_landing_functionality_item_3" [textContent]="'landing_landing_functionality_item_3' | translate">Instructions to connect your local SQL Client and BI tools</li>
        <li i18n="@@landing_landing_functionality_item_4" [textContent]="'landing_landing_functionality_item_4' | translate">Data use tracking and permission management</li>
      </ul>
      <p class="landingFont" i18n="@@landing_landing_blurb_1" [textContent]="'landing_landing_blurb_1' | translate">Additionally, the Xylem Data Lake provides a space for Xylem to deploy rapidly prototyped solutions that continue to push the industry forward.</p>
      <p class="landingFont" i18n="@@landing_landing_blurb_2" [textContent]="'landing_landing_blurb_2' | translate">Explore the art of the possible with your data in the Xylem Data Lake!</p>
    </div>
    <div class="connect">
      <h1 style="font-size: calc(0.75em + 2.3vmin);" i18n="@@landing_landing_connect" [textContent]="'landing_landing_connect' | translate">Connect With Us</h1>
      <p class="landingFont" i18n="@@landing_landing_feedback" [textContent]="'landing_landing_feedback' | translate">
        We want your feedback on how to make the Xylem Data Lake better!
      </p>
      <p class="landingFont" i18n="@@landing_landing_improvements" [textContent]="'landing_landing_improvements' | translate">
        What additional data sets and/or functionalities can we add to build greater value? What things could we modify to enhance usability?
      </p>
      <p class="landingFont" i18n="@@landing_landing_contact" [textContent]="'landing_landing_contact' | translate">
        You can reach us with your questions, comments or request using the following:
      </p>
     <a (click)="learnMore('https://spanenhancements.ideas.aha.io/ideas/new')" i18n="@@landing_landing_contact_link" [textContent]="'landing_landing_contact_link' | translate">Connect Here</a>
    </div>
  </div>
</div>
