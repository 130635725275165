import { Injectable } from '@angular/core';

/**
 * Preferences for a given table
 */
export interface TablePreferences {
  pageSize: number;
  lastPageIndex: number;
}

/**
 * Preferences for a given customer
 */
export interface CustomerPreference {
  tables: Record<string, TablePreferences>;
}

/**
 * User preferences for the application
 * Contains preferences for each customer, but
 * can be expanded to include other more generic preferences
 * such as theme, language, etc.
 */
export interface UserPreferences {
  [key: string]: CustomerPreference;
}

export const DEFAULT_PAGE_SIZE = 5;

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  private readonly userPreferences: UserPreferences;

  constructor() {
    // Initialize user preferences; if they don't exist, create them
    if (!localStorage.getItem('userPreferences')) {
      localStorage.setItem('userPreferences', JSON.stringify({}));
    }

    this.userPreferences = JSON.parse(localStorage.getItem('userPreferences'));
  }

  /**
   * Get the table preferences for a given customer and table. If the preferences
   * don't exist, initialize them.
   * @param customerId Customer ID
   * @param tableId Unique identifier for the table
   * @returns Table preferences object
   */
  getTablePreferences(customerId: string, tableId: string): TablePreferences {
    if (!this.userPreferences[customerId]?.tables[tableId]) {
      this.initTablePreferences(customerId, tableId);
    }

    return this.userPreferences[customerId].tables[tableId];
  }

  /**
   * Update the table preferences for a given customer and table
   * @param customerId Customer ID
   * @param tableId Unique identifier for the table
   * @param preferences New preferences to set
   */
  updateTablePreferences(
    customerId: string,
    tableId: string,
    preferences: TablePreferences
  ): void {
    if (!this.userPreferences[customerId]?.tables[tableId]) {
      this.initTablePreferences(customerId, tableId);
    }

    this.userPreferences[customerId].tables[tableId] = preferences;
    this.updateLocalStorage();
  }

  /**
   * Create the initial table preferences for a given customer and table
   * defaults to the default page size and first page index
   * @param customerId Customer ID
   * @param tableId Unique identifier for the table
   */
  initTablePreferences(customerId: string, tableId: string): void {
    if (!this.userPreferences[customerId]) {
      this.userPreferences[customerId] = {
        tables: {}
      };
    }

    this.userPreferences[customerId].tables[tableId] = {
      pageSize: DEFAULT_PAGE_SIZE,
      lastPageIndex: 0
    };
    this.updateLocalStorage();
  }

  /**
   * Update the user preferences in local storage
   */
  updateLocalStorage(): void {
    localStorage.setItem(
      'userPreferences',
      JSON.stringify(this.userPreferences)
    );
  }
}
