<div
  class="login-dialog"
  *ngIf="
    data['errorType'] === 'invalid-token' &&
    customTranslatorService.userLocaleData
  "
>
  <div class="header-bar">
    <mat-icon class="error" fontIcon="error"></mat-icon>
    <h2
      i18n="@@invalid-login-dialog_invalid-login-dialog_invalid"
      [textContent]="
        'invalid-login-dialog_invalid-login-dialog_invalid' | translate
      "
    >
      Invalid Login
    </h2>
  </div>
  <div mat-dialog-content class="info">
    <h3
      i18n="@@invalid-login-dialog_invalid-login-dialog_description"
      [textContent]="
        'invalid-login-dialog_invalid-login-dialog_description' | translate
      "
    >
      You do not have the appropriate permissions to access utilitydatalake.com
      and will be redirected to the login screen. If this is an error please
      contact admin.
    </h3>
  </div>
</div>

<div
  class="login-dialog"
  *ngIf="
    data['errorType'] === 'clock-skew' && customTranslatorService.userLocaleData
  "
>
  <div class="header-bar">
    <mat-icon class="error" fontIcon="error"></mat-icon>
    <h2
      i18n="@@invalid-login-dialog_invalid-login-dialog_clock"
      [textContent]="
        'invalid-login-dialog_invalid-login-dialog_clock' | translate
      "
    >
      System Clock Out of Sync
    </h2>
  </div>
  <div mat-dialog-content class="clock-skew-content">
    <h4
      i18n="@@invalid-login-dialog_invalid-login-dialog_clock_content_1"
      [textContent]="
        'invalid-login-dialog_invalid-login-dialog_clock_content_1' | translate
      "
    >
      Your system clock appears to be out of synch. This will result in Xylem
      Data Lake suspecting that you are attempting to login in with a
      maliciously acquired token.
    </h4>
    <h4
      i18n="@@invalid-login-dialog_invalid-login-dialog_clock_content_2"
      [textContent]="
        'invalid-login-dialog_invalid-login-dialog_clock_content_2' | translate
      "
    >
      Please ensure your system clock is in synch/accurate and try again.
    </h4>
    <h4
      i18n="@@invalid-login-dialog_invalid-login-dialog_clock_content_3"
      [textContent]="
        'invalid-login-dialog_invalid-login-dialog_clock_content_3' | translate
      "
    >
      If this issue persists after having verified that your system clock is in
      synch/accurate, please contact your admin.
    </h4>
  </div>
</div>
