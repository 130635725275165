import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent, ModalService } from '@gravity-angular/components';
import { SizeType } from '@gravity-angular/models';

@Component({
  selector: 'app-idle-logout-dialog',
  templateUrl: './idle-logout-dialog.component.html',
  styleUrls: ['./idle-logout-dialog.component.scss']
})
export class IdleLogoutDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('idleModal') idleModal: ElementRef;

  continueText = $localize`:@@idle-logout-dialog_idle-logout-dialog_continue:Continue`;
  readonly SizeType = SizeType;
  constructor(
    private readonly modalService: ModalService,
    public dialogRef: MatDialogRef<IdleLogoutDialogComponent>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.openModal(this.idleModal as unknown as ModalComponent);
  }

  openModal(modal: ModalComponent): void {
    this.modalService.openModal(modal);
  }

  close(result: string): void {
    this.dialogRef.close(result);
  }
}
