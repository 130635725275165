/* eslint-disable @typescript-eslint/naming-convention */
export interface Dashboard {
  id: string;
  custid: string;
  dashboard_title: string;
  published: boolean;
  status: string;
  changed_by_name: string;
  changed_on_utc: string;
  owners_id: string;
  owners_username: string;
  params: DashboardFilter[];
  certification_details: string;
  url: string;
  json_metadata: string;
}

export interface DashboardParams {
  filters: DashboardFilter[];
  nativeFilterConfig: NativeFilterConfig[];
}

export interface NativeFilterConfig {
  id: string;
  controlValues: NativeFilterControlValues;
  name: string;
  filterType: string;
  targets: Target[];
  defaultDataMask: DefaultDataMask;
  cascadeParentIds: string[];
}

export interface NativeFilterControlValues {
  defaultToFirstItem?: boolean;
  enableEmptyFilter?: boolean;
  hideFilter?: boolean;
  inverseSelection?: boolean;
  multiSelect?: boolean;
  searchAllOptions?: boolean;
  enableSingleValue?: boolean;
  sortAscending?: boolean;
}

export interface DefaultDataMask {
  __cache?: FilterState;
  extraFormData: ExtraFormData;
  filterState: FilterState;
  ownState?: object;
}

export interface FilterState {
  label?: string;
  validateMessage?: boolean;
  validateStatus?: boolean;
  value?: string[] | string;
}

export interface ExtraFormData {
  filters?: QueryContextFilter[];
  time_range?: string;
}

export interface Target {
  name?: string;
  datasetId?: number;
  column?: {
    name: string;
  };
}

export interface DashboardFilter {
  name: string;
  columnName: string;
  filterType: FilterType;
  defaultValue: string | string[];
  valuesFrom: FilterDataSource;
  dependsOn: string[];
  multiselect: boolean;
  allowNull: boolean;
  enableSingleValue?: boolean;
  hideFilter: boolean;
}

export interface FilterDataSource {
  datasetId: number;
  column: {
    name: string;
  };
}

export interface QueryContextFilter {
  col: string;
  op: string;
  val: string[] | string;
}

export enum FilterType {
  TIME = 'filter_time',
  RANGE = 'filter_range',
  SELECT = 'filter_select'
}

export enum TimeFilterLabel {
  CUSTOM_RANGE = 'Custom Range',
  RELATIVE = 'Relative',
  PREVIOUS = 'Previous'
}

export interface SupersetQueryParams {
  customer: string;
  report: string;
  filters?: string;
}

export interface SupersetQueryFilters {
  name: string;
  value: string;
}

export interface NativeFilters {
  [key: string]: {
    __cache?: FilterState;
    extraFormData: ExtraFormData;
    filterState: FilterState;
    id: string;
    ownState: Object;
  };
}

export interface NativeFilterKey {
  key: string;
}
