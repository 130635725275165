import { Injectable } from '@angular/core';
import { DateFormat } from '@gravity-angular/utils';

import moment from 'moment';

@Injectable()
export class DateConversionsService {
  getTodaysDateAsString(): string {
    return moment().format(DateFormat.api);
  }

  getCurrentDateTime(): Date {
    return moment().toDate();
  }

  formatUTCDateAsReadable(date: string): string {
    if (date) {
      return moment(date).format(DateFormat.readable);
    }

    return null;
  }
}
