import { environment } from '@env/environment';

export enum FeatureFlag {
  DATA_IMPORT = 'DATA_IMPORT',
  HEALTH_MONITOR = 'HEALTH_MONITOR',
  CLUSTER_ANALYSIS = 'CLUSTER_ANALYSIS',
  APP_STORE = 'APP_STORE',
  BUSINESS_RULE_ENGINE = 'BUSINESS_RULE_ENGINE',
}

export interface FeatureFlags {
  [FeatureFlag.DATA_IMPORT]: boolean;
  [FeatureFlag.HEALTH_MONITOR]: boolean;
  [FeatureFlag.CLUSTER_ANALYSIS]: boolean;
  [FeatureFlag.APP_STORE]: boolean;
  [FeatureFlag.BUSINESS_RULE_ENGINE]: boolean;
}

export class FeatureFlagsService {
  private readonly featureFlags: FeatureFlags;

  constructor() {
    this.featureFlags = environment.featureFlags;
  }

  getFeatureFlags(): FeatureFlags {
    return this.featureFlags;
  }

  isFeatureEnabled(featureFlag: FeatureFlag): boolean {
    return this.featureFlags[featureFlag] || false;
  }
}
