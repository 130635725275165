import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SizeType } from '@gravity-angular/models';
import { ModalComponent, ModalService } from '@gravity-angular/components';
import { CustomTranslatorService } from '../services/custom-translator/custom-translator.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmationModal') confirmationModal: ElementRef;

  confirmText = $localize`:@@confirmation-dialog_confirmation-dialog_confirm:Confirm`;
  closeText = $localize`:@@confirmation-dialog_confirmation-dialog_close:Close`;
  readonly SizeType = SizeType;

  title: string;
  description: string;
  emphasis: string;
  showCancelButton: boolean;

  constructor(
    private readonly modalService: ModalService,
    public customTranslatorService: CustomTranslatorService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.description = this.data.description;
    this.emphasis = this.data.emphasis;
    this.showCancelButton = this.data.showCancelButton ?? true;
  }

  ngAfterViewInit(): void {
    this.openModal(this.confirmationModal as unknown as ModalComponent);
  }

  openModal(modal: ModalComponent): void {
    this.modalService.openModal(modal);
  }

  close(result?: string): void {
    this.dialogRef.close(result);
  }

  buttonText(): string {
    return this.data.buttonText === 'Close'
      ? this.customTranslatorService.translate(
          $localize`:@@confirmation-dialog_confirmation-dialog_close:Close`,
          'confirmation-dialog_confirmation-dialog_close'
        )
      : this.customTranslatorService.translate(
          $localize`:@@confirmation-dialog_confirmation-dialog_confirm:Confirm`,
          'confirmation-dialog_confirmation-dialog_confirm'
        );
  }
}
