import { Injectable } from '@angular/core';

/**
 * Service to manage the application's "state".
 * This service provides methods to set and get the current state.
 * Ideally used to share state between components/routes.
 */
@Injectable({
  providedIn: 'root'
})
export class StateService {
  private state: State;

  constructor() {}

  /**
   * Sets the current state.
   *
   * @param {State} state - The new state to be set.
   */
  setState(state: State): void {
    this.state = state;
  }

  /**
   * Retrieves the current state.
   *
   * @returns The current state.
   */
  getState(): State {
    return this.state;
  }

  /**
   * Clears the current state by setting it to null.
   */
  clearState(): void {
    this.state = null;
  }
}

export interface State {
  [key: string]: any;
}
