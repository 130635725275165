<xg-modal #idleModal
    [size]="SizeType.sm"
    [primaryActionText]="'idle-logout-dialog_idle-logout-dialog_continue' | translate"
    (primaryClick)="close('resume')"
    (secondaryClick)="close('resume')">
    <div title>
        <div class="header-bar">
            <div class="row">
                <mat-icon class="warning">warning</mat-icon>
                <span i18n="@@idle-logout-dialog_idle-logout-dialog_timeout" [textContent]="'idle-logout-dialog_idle-logout-dialog_timeout' | translate">
                    Idle Timeout
                </span>
            </div>
        </div>
    </div>
    <div content>
        <p i18n="@@idle-logout-dialog_idle-logout-dialog_two_minutes" [textContent]="'idle-logout-dialog_idle-logout-dialog_two_minutes' | translate">
            You have been idle for awhile and for security reasons your XDL session will end in 2 minutes.
        </p>
        <p i18n="@@idle-logout-dialog_idle-logout-dialog_continue_session" [textContent]="'idle-logout-dialog_idle-logout-dialog_continue_session' | translate">
            Would you like to continue your current session?
        </p>
    </div>
</xg-modal>