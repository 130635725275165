import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomTranslatorService } from 'app/shared/services/custom-translator/custom-translator.service';

@Component({
  selector: 'app-invalid-login-dialog',
  templateUrl: './invalid-login-dialog.component.html',
  styleUrls: ['./invalid-login-dialog.component.scss']
})
export class InvalidLoginDialogComponent implements OnInit {
  errorType: string;

  constructor(
    public customTranslatorService: CustomTranslatorService,
    public dialogRef: MatDialogRef<InvalidLoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Object
  ) {
    this.errorType = this.data['errorType'];
  }

  async ngOnInit(): Promise<void> {
    if (!this.customTranslatorService.userLocaleData) {
      await this.customTranslatorService.getLocalizationFile('en_US', true);
    }
  }
}
