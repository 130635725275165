import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { AmplifyService } from '../aws/amplify/amplify.service';

@Component({
  selector: 'app-user-menu-dialog',
  templateUrl: './user-menu-dialog.component.html',
  styleUrls: ['./user-menu-dialog.component.scss']
})
export class UserMenuDialogComponent implements OnInit {
  downloadText = $localize`:@@driver-download-dialog_driver-download-dialog_download:Download`;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserMenuDialogComponent>,
    private readonly amplifyService: AmplifyService
  ) {}

  ngOnInit(): void {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  async getDriver(version: string) {
    const result = await this.amplifyService.downloadUrl(
      `${version}.zip`,
      environment.odbcBucket
    );
    const a = document.createElement('a');
    a.href = result.url?.toString();
    a.download = `${version}.zip`;
    a.click();
    this.dialogRef.close();

    return;
  }
}
