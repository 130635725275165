/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AmplifyService } from 'app/aws/amplify/amplify.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class S2sLoginMgmtService {
  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly amplifyService: AmplifyService
  ) {}

  async api(method, apiName, path, init): Promise<any> {
    const response = await this.amplifyService.callAPI(
      method,
      apiName,
      path,
      init,
    );

    if (this.isEmpty(response)) {
      throw new Error(`API ${method} ${apiName}${path} failed.`);
    }

    return response['data'];
  }

  async getLogins(custid: string): Promise<object[]> {
    return this.api('get', 'API_UDL_S2S_MGMT', '/get-logins', {
      queryParams: { customerId: custid.toLowerCase() }
    });
  }

  async getLoginsHistory(custid: string) {
    return this.api('get', 'API_UDL_S2S_MGMT', '/get-history', {
      queryParams: { customerId: custid.toLowerCase() }
    });
  }

  async addLogin(
    custid: string,
    username: string,
    description: string,
    cluster: string
  ) {
    const userData = await lastValueFrom(
      this.oidcSecurityService.getUserData()
    );

    return this.api('POST', 'API_UDL_S2S_MGMT', '/create-login', {
      body: {
        customerId: custid,
        username,
        description,
        currentUser: userData['preferred_username'],
        cluster
      }
    });
  }

  async deleteLogin(login, cluster: string) {
    const userData = await lastValueFrom(
      this.oidcSecurityService.getUserData()
    );
    const params = {
      customerId: login.customer_id,
      loginId: login.login_id,
      username: login.username,
      cluster,
      currentUser: userData['preferred_username']
    }
    let path = '/delete-login';
    const urlParams = new URLSearchParams();

    for (const key of Object.keys(params)) {
      urlParams.append(key, params[key]);
    }

    const queryString = urlParams.toString();
    path = `${path}?${queryString}`;

    return this.api('DELETE', 'API_UDL_S2S_MGMT', path, {});
  }

  async resetLogin(login, cluster: string) {
    const userData = await lastValueFrom(
      this.oidcSecurityService.getUserData()
    );

    return this.api('PATCH', 'API_UDL_S2S_MGMT', '/reset-login', {
      body: {
        customerId: login.customer_id,
        loginId: login.login_id,
        username: login.username,
        cluster,
        currentUser: userData['preferred_username']
      }
    });
  }

  private isEmpty(obj) {
    return obj === undefined || Object.keys(obj).length === 0;
  }
}
